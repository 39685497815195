import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { useGetByIdCronogramaMutation, useUpdateCronogramaMutation } from '../../../store/api/endpoints/cronogramaApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CropImage from '../../elementos/CropImage'
import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import { Breadcrumbs, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import EventIcon from '@mui/icons-material/Event'
import CheckIcon from '@mui/icons-material/Check'

export default function CronogramaEditar() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const urlParams = useParams()


    const [componenteCargado, setComponenteCargado] = useState(false)
    const [titulo, setTitulo] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [fecha, setFecha] = useState('')
    const [hora, setHora] = useState('')
    const [modalidad, setModalidad] = useState('')
    const [imagen, setImagen] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetByIdCronogramaMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateCronogramaMutation()


    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            titulo,
            descripcion,
            fecha,
            hora,
            modalidad,
            imagen,
        }
        updateRegistro({ idCronograma: urlParams.idCronograma, body })

    }

    useEffect(() => {
        getRegistro(urlParams.idCronograma)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            setImagen(responseGetRegistro.imagen)
            setTitulo(responseGetRegistro.titulo)
            setDescripcion(responseGetRegistro.descripcion)
            setFecha(moment(responseGetRegistro.fechayhora).format("YYYY-MM-DD"))
            setHora(moment(responseGetRegistro.fechayhora).format("HH:mm:ss"))
            setModalidad(responseGetRegistro.modalidad)

            setComponenteCargado(true)
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro actualizado!"
            }))

            navigate("/cronograma")

        }
    }, [isSuccessUpdateRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro || !componenteCargado) return (<Loading />)
    if (isSuccessGetRegistro && componenteCargado) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/cronograma">
                            <Typography color="text.primary">Cronogramas</Typography>
                        </Link>
                        <Typography color="text.primary">{responseGetRegistro.titulo}</Typography>
                        <Typography color="text.primary">Editar</Typography>
                    </Breadcrumbs>
                }
                icon={<EventIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <CropImage
                            imagen={imagen}
                            setRoute={setImagen}
                            maxSize="300000"
                            width={900}
                            height={900}
                            ruta="cronograma"
                            formatosPermitidos="image/jpeg, image/png"
                            avatar
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Input
                            label="Título"
                            icon={<EventIcon />}
                            value={titulo}
                            onChange={setTitulo}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Input
                            label="Descripción"
                            icon={<EventIcon />}
                            value={descripcion}
                            onChange={setDescripcion}
                            type="text"
                            multiline
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Fecha"
                            icon={<EventIcon />}
                            value={fecha}
                            onChange={setFecha}
                            type="date"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Hora"
                            icon={<EventIcon />}
                            value={hora}
                            onChange={setHora}
                            type="time"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Modalidad"
                            icon={<EventIcon />}
                            value={modalidad}
                            onChange={setModalidad}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Divider />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}