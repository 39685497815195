import { tiendasApi } from '../api'

export const productosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPaginatedProductos: builder.mutation({
            query: ({ body }) => ({
                url: 'productos/get-paginated',
                method: 'POST',
                body: body,
            })
        }),
        getByIdProducto: builder.mutation({
            query: (idProducto) => ({
                url: 'productos/' + idProducto,
                method: 'GET',
            })
        }),
        createProducto: builder.mutation({
            query: ({ body }) => ({
                url: 'productos',
                method: 'POST',
                body: body,
            })
        }),
        updateProducto: builder.mutation({
            query: ({ idProducto, body }) => ({
                url: 'productos/' + idProducto,
                method: 'PUT',
                body: body,
            })
        }),
        removeProducto: builder.mutation({
            query: (idProducto) => ({
                url: 'productos/' + idProducto,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPaginatedProductosMutation,
    useGetByIdProductoMutation,
    useCreateProductoMutation,
    useUpdateProductoMutation,
    useRemoveProductoMutation,
} = productosApiSlice