import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        nombre: null,
        permisos: [],
    },
    reducers: {
        setCredentials: (state, action) => {
            state.token = action.payload.token
            state.nombre = action.payload.nombre
            state.permisos = action.payload.permisos
            localStorage.setItem("token", action.payload.token)
        },
        forzarRefreshToken: (state, action) => {
            state.token = null;
        },
        logOut: (state, action) => {
            state.token = null;
            state.nombre = null;
            state.permisos = [];
            localStorage.removeItem("token");
        },
    }
});

export const { setCredentials, forzarRefreshToken, logOut } = authSlice.actions;

export const getUsuarioNombre = (state) => state.auth.nombre;
export const getUsuarioToken = (state) => state.auth.token;
export const getUsuarioPermisos = (state) => state.auth.permisos;



