import { React, useState, useRef, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import imageCompression from 'browser-image-compression'

import { useCreateArchivoMutation } from '../../store/api/endpoints/rutasApiSlice'

import Loading from '../elementos/Loading'

import TextoConIcono from '../elementos/TextoConIcono'

import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import sinImagen from '../../assets/images/sin-imagen.jpg'


import CreateIcon from '@mui/icons-material/Create'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw'
import ZoomInIcon from '@mui/icons-material/ZoomIn'

export default function ({ imagen, setRoute, maxSizeMB, width, height, ruta, avatar }) {

    const usarImageCompression = true

    var editor = ""

    const inputFile = useRef(null)
    const [dialogoOpen, setDialogoOpen] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [extension, setExtension] = useState("")
    const [picture, setPicture] = useState({
        img: null,
        zoom: 2,
        rotate: 0,
        croppedImg: (imagen ? imagen : sinImagen)
    })

    const [
        uploadFile,
        {
            data: responseUploadFile,
            isUninitialized: isUninitializedUploadFile,
            isLoading: isLoadingUploadFile,
            isSuccess: isSuccessUploadFile,
            isError: isErrorUploadFile,
            error: errorDataUploadFile
        }
    ] = useCreateArchivoMutation()

    const handleClickAbrirDialogo = () => {
        setDialogoOpen(true)
        inputFile.current.click()
    }

    const handleClickCerrarDialogo = () => {
        setDialogoOpen(false)
    }

    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value,
        })
    }

    const handleRotate = (event, value) => {
        setPicture({
            ...picture,
            rotate: value,
        })
    }

    const handleCancel = () => {
        setPicture({
            ...picture,
        })
    }

    const setEditorRef = (ed) => {
        editor = ed
    }

    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas()
            const croppedImg = canvasScaled.toDataURL()

            setPicture({
                ...picture,
                img: null,
                croppedImg: croppedImg
            })

            setSpinner(true)

            convertirBase64ySubir(croppedImg)

        }
    }

    const urltoFile = (url, filename, mimeType) => {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType })
            })
        )
    }

    async function convertirBase64ySubir(base64) {

        let nombreTemporalArchivo = Math.random().toString(16).slice(2) + "." + extension

        let imageFile = await new Promise((resolve, reject) => {
            urltoFile(base64, nombreTemporalArchivo)
                .then((file) => {

                    if (usarImageCompression) {

                        resolve(file)

                    } else {

                        let nombreTemporalArchivo = Math.random().toString(16).slice(2) + "." + extension

                        var formData = new FormData()
                        formData.append('file', file, nombreTemporalArchivo)

                        uploadFile({
                            carpeta: ruta,
                            body: formData,
                        })

                    }



                })
        })

        const options = {
            maxSizeMB: maxSizeMB,
            maxWidthOrHeight: (width > height ? width : height),
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options)

            let nombreTemporalArchivo = Math.random().toString(16).slice(2) + "." + extension

            var formData = new FormData()
            formData.append('file', compressedFile, nombreTemporalArchivo)

            uploadFile({
                carpeta: ruta,
                body: formData,
            })

        } catch (error) {
            console.log(error)
        }

    }

    const handleFileChange = (e) => {

        let url = URL.createObjectURL(e.target.files[0])
        let nombre = e.target.files[0].name.split(".")
        setExtension(nombre[nombre.length - 1])

        setPicture({
            ...picture,
            img: url,
        })
    }

    useEffect(() => {

        if (isSuccessUploadFile) {
            setDialogoOpen(false)
            setSpinner(false)
            setRoute(responseUploadFile.url)
        }

        if (isErrorUploadFile) {
            console.log("Error al subir la imagen")
        }


    })

    return (
        <>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12 }} align="center">
                    {
                        avatar && <Avatar sx={{ height: '75px', width: '75px' }} src={picture.croppedImg} />
                    }
                    {
                        !avatar && <img src={picture.croppedImg} width="100%" />
                    }
                    <Button onClick={handleClickAbrirDialogo} size='small' startIcon={<CreateIcon />} variant="outlined">Editar</Button>
                    <input style={{ display: 'none' }} ref={inputFile} type="file" accept="image/*" onChange={handleFileChange} />
                </Grid>
            </Grid>

            <Dialog
                open={dialogoOpen}
                onClose={handleClickCerrarDialogo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Cargar Imágen"}
                </DialogTitle>
                <DialogContent>
                    {
                        (spinner || isLoadingUploadFile) && (
                            <>
                                <Loading />
                                <p>Se está cargando la imágen, por favor espera.</p>
                            </>
                        )
                    }
                    {
                        (!spinner && !isLoadingUploadFile) && (
                            <Grid container spacing={2}>
                                <Grid size={{ xs: 12 }}>
                                    <AvatarEditor
                                        ref={setEditorRef}
                                        image={picture.img}
                                        width={width}
                                        height={height}
                                        border={50}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        rotate={picture.rotate}
                                        scale={picture.zoom}
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }} align="center">
                                    <TextoConIcono icono={<ZoomInIcon />} texto="Zoom" />
                                    <Slider
                                        aria-label="raceSlider"
                                        value={picture.zoom}
                                        min={1}
                                        max={10}
                                        step={0.1}
                                        onChange={handleSlider}
                                    ></Slider>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }} align="center">
                                    <TextoConIcono icono={<Rotate90DegreesCwIcon />} texto="Rotación" />
                                    <Slider
                                        aria-label="raceSlider"
                                        value={picture.rotate}
                                        min={0}
                                        max={180}
                                        step={1}
                                        onChange={handleRotate}
                                    ></Slider>
                                </Grid>
                            </Grid>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCerrarDialogo}>Cancelar</Button>
                    <Button onClick={handleSave} autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

