import { useState, useEffect } from 'react'

import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'
import 'moment-timezone'

import Loading from './Loading'
import ButtonIcon from './ButtonIcon'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { TextField } from '@mui/material'

import '../../assets/css/table.css'

import SearchIcon from '@mui/icons-material/Search'
import CircleIcon from '@mui/icons-material/Circle'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import moment from 'moment-timezone'

const delay = 0.4

function IconoDelOrder({
    orden
}) {

    if (orden === 0) return (<> <NorthIcon style={{ fontSize: '0.8rem' }} /></>)
    if (orden === 1) return (<> <SouthIcon style={{ fontSize: '0.8rem' }} /></>)
    return (<></>)
}

export default function TablaPaginada({
    api,
    thead,
    tbody,
    orderDefault,
    extraParams,
}) {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")
    const [order, setOrder] = useState(orderDefault)

    const ordenarPorColumna = (posicion) => {

        if (thead[posicion].ordenable) {
            if (order[0] === posicion) {
                //misma posicion, ordeno en otro sentido
                setOrder([posicion, (order[1] > 0 ? 0 : 1)])
            } else {
                setOrder([posicion, 1])
            }
        }

    }

    const [
        getRegistros,
        {
            data: returnedData,
            isUninitialized,
            isLoading,
            isSuccess,
            isError,
        }
    ] = api()

    useEffect(() => {

        let timer1 = setTimeout(() => {

            getRegistros({
                extraParams: extraParams,
                body: { page, textsearch, order },
            })

        }, delay * 1000)

        return () => {
            clearTimeout(timer1)
        }

    }, [textsearch])

    useEffect(() => {

        getRegistros({
            extraParams: extraParams,
            body: { page, textsearch, order },
        })

    }, [page, order, extraParams])



    if (isLoading || isUninitialized) return (<Loading />)

    return (
        <>
            <Grid container spacing={2}>
                <Grid className="titulo" size={{ xs: 12, md: 8 }} sx={{textAlign: {sm: 'center', md: 'left'}, paddingLeft: {sm: 'auto', md: '5px'}}}>
                    {returnedData?.totalDocs === 0 && <p>No se encontraron registros.</p>}
                    {returnedData?.totalDocs === 1 && <p>Se encontró {returnedData?.totalDocs} registro.</p>}
                    {returnedData?.totalDocs > 1 && <p>Se encontraron {returnedData?.totalDocs} registros.</p>}
                </Grid>
                <Grid className="buscador" size={{ xs: 12, md: 4 }} sx={{marginTop: {sm: 'auto', md: '10px'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField autoComplete='off' autoFocus onChange={(e) => setTextsearch(e.target.value)} fullWidth label="Buscar..." variant="standard" value={textsearch} />
                    </Box>
                </Grid>
            </Grid>
            <div className="tableContainer">
                <table className="formato1" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            {
                                thead.map((element, index) => (
                                    <th
                                        key={index}
                                        align={element.align}
                                        onClick={() => ordenarPorColumna(index)}
                                        className={element.ordenable ? "ordenable" : ""}
                                    >
                                        {element.label}{order[0] === index && <IconoDelOrder orden={order[1]} />}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            returnedData?.totalDocs > 0 && returnedData.docs.map((registro, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            tbody.map((elemento, indexTd) => {
                                                return (
                                                    <td key={indexTd} align={thead[indexTd]?.align}>
                                                        {elemento.tipo === "img" && <img src={registro[elemento.valor]} height="35" />}
                                                        {elemento.tipo === "texto" && registro[elemento.valor]}
                                                        {(elemento.tipo === "fecha" && registro[elemento.valor] && moment(registro[elemento.valor]).isValid()) &&
                                                            <Moment
                                                                format="DD-MM-YYYY"
                                                                date={registro[elemento.valor]}
                                                                tz="America/Argentina/Buenos_Aires"
                                                            />
                                                        }
                                                        {(elemento.tipo === "fechahora" && registro[elemento.valor] && moment(registro[elemento.valor]).isValid()) &&
                                                            <Moment
                                                                format="DD-MM-YYYY HH:mm:ss"
                                                                date={registro[elemento.valor]}
                                                                tz="America/Argentina/Buenos_Aires"
                                                            />
                                                        }
                                                        {(elemento.tipo === "fechacustom" && registro[elemento.valor] && moment(registro[elemento.valor]).isValid()) &&
                                                            <Moment
                                                                format={elemento.options}
                                                                date={registro[elemento.valor]}
                                                                tz="America/Argentina/Buenos_Aires"
                                                            />
                                                        }
                                                        {elemento.tipo === "precio" &&
                                                            <NumericFormat
                                                                value={registro[elemento.valor]}
                                                                className="foo"
                                                                displayType={'text'}
                                                                decimalSeparator={elemento.options[0]}
                                                                thousandSeparator={elemento.options[1]}
                                                                prefix={elemento.options[2]}
                                                            />
                                                        }
                                                        {elemento.tipo === "verdaderofalso" &&
                                                            (registro[elemento.valor] ? 'Sí' : 'No')
                                                        }
                                                        {elemento.tipo === "estado" && <CircleIcon style={{ fontSize: '0.9rem', color: (registro[elemento.valor] ? 'green' : 'red') }} />}
                                                        {elemento.tipo === "botones" && elemento.valor.map((unBoton, indexBoton) => (
                                                            <ButtonIcon
                                                                key={indexBoton}
                                                                variant="outlined"
                                                                size="small"
                                                                color={unBoton.color}
                                                                startIcon={unBoton.icono}
                                                                to={unBoton.ruta + registro[unBoton.valor]}
                                                                marginRight
                                                            />
                                                        ))}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            returnedData?.totalDocs === 0 &&
                            <tr><td colSpan={thead.length}>No se encontraron registros.</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                className="table-pagination"
                showFirstButton showLastButton
                shape="rounded"
                count={returnedData?.totalPages}
                page={returnedData?.page * 1}
                onChange={(event, value) => setPage(value)}
            />

        </>
    )

}