import { tiendasApi } from '../api'

export const descuentosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPaginatedDescuentos: builder.mutation({
            query: ({ body }) => ({
                url: 'descuentos/get-paginated',
                method: 'POST',
                body: body,
            })
        }),
        getByIdDescuento: builder.mutation({
            query: (idDescuento) => ({
                url: 'descuentos/' + idDescuento,
                method: 'GET',
            })
        }),
        createDescuento: builder.mutation({
            query: ({ body }) => ({
                url: 'descuentos',
                method: 'POST',
                body: body,
            })
        }),
        updateDescuento: builder.mutation({
            query: ({ idDescuento, body }) => ({
                url: 'descuentos/' + idDescuento,
                method: 'PUT',
                body: body,
            })
        }),
        removeDescuento: builder.mutation({
            query: (idDescuento) => ({
                url: 'descuentos/' + idDescuento,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPaginatedDescuentosMutation,
    useGetByIdDescuentoMutation,
    useCreateDescuentoMutation,
    useUpdateDescuentoMutation,
    useRemoveDescuentoMutation,
} = descuentosApiSlice