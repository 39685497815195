import { React } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useGetPaginatedDescuentosMutation } from '../../../store/api/endpoints/descuentosApiSlice'

import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'


import Button from '@mui/material/Button'
import { Breadcrumbs, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'

export default function Descuentos() {

    const thead = []
    thead.push({ label: "Imagen", align: "center", ordenable: false })
    thead.push({ label: "Inicio", align: "center", ordenable: true })
    thead.push({ label: "Fin", align: "center", ordenable: true })
    thead.push({ label: "Título", align: "left", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "img", valor: "imagen" })
    tbody.push({ tipo: "fecha", valor: "fechaInicio" })
    tbody.push({ tipo: "fecha", valor: "fechaFin" })
    tbody.push({ tipo: "texto", valor: "titulo" })
    let botones = []
    botones.push({ color: "primary", icono: <CreateIcon />, ruta: "/descuentos/editar/", valor: '_id' })
    botones.push({ color: "error", icono: <DeleteIcon />, ruta: "/descuentos/eliminar/", valor: '_id' })
    tbody.push({ tipo: "botones", valor: botones })


    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Descuentos</Typography>
                    </Breadcrumbs>
                }
                icon={<LocalOfferIcon />}
                end={<Link to="/descuentos/crear">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Nuevo
                    </Button>
                </Link>}
            />
            <TablaPaginada
                api={useGetPaginatedDescuentosMutation}
                thead={thead}
                tbody={tbody}
                orderDefault={[2, 1]}
            />
        </>
    )

}