import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetByIdProductoMutation, useUpdateProductoMutation } from '../../../store/api/endpoints/productosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CropImage from '../../elementos/CropImage'
import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import { Breadcrumbs, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import EventIcon from '@mui/icons-material/Event'
import CheckIcon from '@mui/icons-material/Check'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import LinkIcon from '@mui/icons-material/Link'

export default function ProductosEditar() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const urlParams = useParams()


    const [componenteCargado, setComponenteCargado] = useState(false)
    const [titulo, setTitulo] = useState('')
    const [url, setUrl] = useState('')
    const [imagen, setImagen] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetByIdProductoMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateProductoMutation()


    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            titulo,
            url,
            imagen,
        }
        updateRegistro({ idProducto: urlParams.idProducto, body })

    }

    useEffect(() => {
        getRegistro(urlParams.idProducto)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            setImagen(responseGetRegistro.imagen)
            setTitulo(responseGetRegistro.titulo)
            setUrl(responseGetRegistro.url)

            setComponenteCargado(true)
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro actualizado!"
            }))

            navigate("/productos")

        }
    }, [isSuccessUpdateRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro || !componenteCargado) return (<Loading />)
    if (isSuccessGetRegistro && componenteCargado) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/productos">
                            <Typography color="text.primary">Productos</Typography>
                        </Link>
                        <Typography color="text.primary">{responseGetRegistro.titulo}</Typography>
                        <Typography color="text.primary">Editar</Typography>
                    </Breadcrumbs>
                }
                icon={<StarOutlineIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <CropImage
                            imagen={imagen}
                            setRoute={setImagen}
                            maxSize="300000"
                            width={500}
                            height={500}
                            ruta="descuentos"
                            formatosPermitidos="image/jpeg, image/png"
                            avatar
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Input
                            label="Título"
                            icon={<EventIcon />}
                            value={titulo}
                            onChange={setTitulo}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Input
                            label="URL"
                            icon={<LinkIcon />}
                            value={url}
                            onChange={setUrl}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Divider />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}