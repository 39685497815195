import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import EventIcon from '@mui/icons-material/Event'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import GroupIcon from '@mui/icons-material/Group'

export const SidebarIcono = ({ codigo }) => {

    if (codigo == 1) return (<WysiwygIcon fontSize="small" />)
    if (codigo == 2) return (<EventIcon fontSize="small" />)
    if (codigo == 3) return (<LocalOfferIcon fontSize="small" />)
    if (codigo == 4) return (<StarOutlineIcon fontSize="small" />)
    if (codigo == 5) return (<GroupIcon fontSize="small" />)



    return (<CloseIcon />)

}
