import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { useCreateProductoMutation } from '../../../store/api/endpoints/productosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CropImage from '../../elementos/CropImage'
import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import { Breadcrumbs, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import CheckIcon from '@mui/icons-material/Check'
import LinkIcon from '@mui/icons-material/Link'
import StarOutlineIcon from '@mui/icons-material/StarOutline'

export default function DescuentosCrear() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [titulo, setTitulo] = useState('')
    const [url, setUrl] = useState('')
    const [imagen, setImagen] = useState('')

    const [
        crearRegistro,
        {
            data: responseCrearRegistro,
            isUninitialized: isUninitializedCrearRegistro,
            isLoading: isLoadingCrearRegistro,
            isSuccess: isSuccessCrearRegistro,
            isError: isErrorCrearRegistro,
            error: errorDataCrearRegistro,
        }
    ] = useCreateProductoMutation()


    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            titulo,
            url,
            imagen,
        }
        crearRegistro({ body })

    }

    useEffect(() => {
        if (isErrorCrearRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCrearRegistro.data.message
            }))
        }
    }, [isErrorCrearRegistro])

    useEffect(() => {
        if (isSuccessCrearRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro creado!"
            }))

            navigate("/productos")

        }
    }, [isSuccessCrearRegistro])

    if (isLoadingCrearRegistro) return (<Loading />)

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/productos">
                            <Typography color="text.primary">Productos</Typography>
                        </Link>
                        <Typography color="text.primary">Crear</Typography>
                    </Breadcrumbs>
                }
                icon={<StarOutlineIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <CropImage
                            imagen={imagen}
                            setRoute={setImagen}
                            maxSize="300000"
                            width={500}
                            height={500}
                            ruta="descuentos"
                            formatosPermitidos="image/jpeg, image/png"
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Input
                            label="Título"
                            icon={<StarOutlineIcon />}
                            value={titulo}
                            onChange={setTitulo}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Input
                            label="URL"
                            icon={<LinkIcon />}
                            value={url}
                            onChange={setUrl}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Divider />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}