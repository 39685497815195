import { React } from 'react'
import { useParams } from 'react-router-dom'

import { useGetPaginatedAbonadosMutation } from '../../../store/api/endpoints/abonadosApiSlice'

import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'
import MenuOptions from '../../elementos/MenuOptions'

import { Breadcrumbs, Typography } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import GroupIcon from '@mui/icons-material/Group'
import CircleIcon from '@mui/icons-material/Circle'


export default function Abonados() {

    const urlParams = useParams()
    
    const filtroAplicado = urlParams.filtro.charAt(0).toUpperCase() + urlParams.filtro.slice(1)

    const thead = []
    thead.push({ label: "Activo", align: "center", ordenable: false })
    thead.push({ label: "Alta", align: "center", ordenable: true })
    thead.push({ label: "DNI", align: "center", ordenable: true })
    thead.push({ label: "CUIT", align: "center", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Email", align: "left", ordenable: true })
    thead.push({ label: "Teléfono", align: "left", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "estado", valor: "activo" })
    tbody.push({ tipo: "fechahora", valor: "date_completa_form" })
    tbody.push({ tipo: "texto", valor: "dni" })
    tbody.push({ tipo: "texto", valor: "cuit" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "email" })
    tbody.push({ tipo: "texto", valor: "telefono" })
    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/abonados/ficha/", valor: '_id' })
    tbody.push({ tipo: "botones", valor: botones })


    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Abonados</Typography>
                        <Typography color="text.primary">{filtroAplicado}</Typography>
                    </Breadcrumbs>
                }
                icon={<GroupIcon />}
                end={<MenuOptions
                    titulo={filtroAplicado}
                    opciones={[
                        {
                            titulo: "Todos",
                            icono: <CircleIcon style={{ color: 'black' }} />,
                            to: '/abonados/todos',
                        },
                        {
                            titulo: "Activos",
                            icono: <CircleIcon style={{ color: 'green' }} />,
                            to: '/abonados/activos',
                        },
                        {
                            titulo: "Inactivos",
                            icono: <CircleIcon style={{ color: 'red' }} />,
                            to: '/abonados/inactivos',
                        }
                    ]}
                />}
            />
            <TablaPaginada
                api={useGetPaginatedAbonadosMutation}
                extraParams={{filtro: urlParams.filtro}}
                thead={thead}
                tbody={tbody}
                orderDefault={[1, 1]}
            />
        </>
    )

}