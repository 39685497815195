import { React } from 'react'
import { Link } from 'react-router-dom'

import { useGetPaginatedProductosMutation } from '../../../store/api/endpoints/productosApiSlice'

import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'


import Button from '@mui/material/Button'
import { Breadcrumbs, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import StarOutlineIcon from '@mui/icons-material/StarOutline'

export default function Productos() {

    const thead = []
    thead.push({ label: "Imagen", align: "center", ordenable: false })
    thead.push({ label: "Titulo", align: "center", ordenable: true })
    thead.push({ label: "URL", align: "center", ordenable: false })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "img", valor: "imagen" })
    tbody.push({ tipo: "texto", valor: "titulo" })
    tbody.push({ tipo: "texto", valor: "url" })
    let botones = []
    botones.push({ color: "primary", icono: <CreateIcon />, ruta: "/productos/editar/", valor: '_id' })
    botones.push({ color: "error", icono: <DeleteIcon />, ruta: "/productos/eliminar/", valor: '_id' })
    tbody.push({ tipo: "botones", valor: botones })


    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Productos</Typography>
                    </Breadcrumbs>
                }
                icon={<StarOutlineIcon />}
                end={<Link to="/productos/crear">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Nuevo
                    </Button>
                </Link>}
            />
            <TablaPaginada
                api={useGetPaginatedProductosMutation}
                thead={thead}
                tbody={tbody}
                orderDefault={[1, 0]}
            />
        </>
    )

}