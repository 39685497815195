import { tiendasApi } from '../api'

export const cronogramaApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPaginatedCronograma: builder.mutation({
            query: ({ body }) => ({
                url: 'cronograma/get-paginated',
                method: 'POST',
                body: body,
            })
        }),
        getByIdCronograma: builder.mutation({
            query: (idCronograma) => ({
                url: 'cronograma/' + idCronograma,
                method: 'GET',
            })
        }),
        createCronograma: builder.mutation({
            query: ({ body }) => ({
                url: 'cronograma',
                method: 'POST',
                body: body,
            })
        }),
        updateCronograma: builder.mutation({
            query: ({ idCronograma, body }) => ({
                url: 'cronograma/' + idCronograma,
                method: 'PUT',
                body: body,
            })
        }),
        removeCronograma: builder.mutation({
            query: (idCronograma) => ({
                url: 'cronograma/' + idCronograma,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPaginatedCronogramaMutation,
    useGetByIdCronogramaMutation,
    useCreateCronogramaMutation,
    useUpdateCronogramaMutation,
    useRemoveCronogramaMutation,
} = cronogramaApiSlice