import { React, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Moment from 'react-moment'

import { useGetByIdCronogramaMutation, useRemoveCronogramaMutation } from '../../../store/api/endpoints/cronogramaApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import { Breadcrumbs, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import EventIcon from '@mui/icons-material/Event'
import DeleteIcon from '@mui/icons-material/Delete'

export default function CronogramaEliminar() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const urlParams = useParams()


    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetByIdCronogramaMutation()

    const [
        removeRegistro,
        {
            data: responseRemoveRegistro,
            isUninitialized: isUninitializedRemoveRegistro,
            isLoading: isLoadingRemoveRegistro,
            isSuccess: isSuccessRemoveRegistro,
            isError: isErrorRemoveRegistro,
            error: errorDataRemoveRegistro,
        }
    ] = useRemoveCronogramaMutation()

    useEffect(() => {
        getRegistro(urlParams.idCronograma)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])


    useEffect(() => {
        if (isErrorRemoveRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemoveRegistro.data.message
            }))
        }
    }, [isErrorRemoveRegistro])

    useEffect(() => {
        if (isSuccessRemoveRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro eliminado!"
            }))

            navigate("/cronograma")

        }
    }, [isSuccessRemoveRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingRemoveRegistro) return (<Loading />)
    if (isSuccessGetRegistro) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/cronograma">
                            <Typography color="text.primary">Cronogramas</Typography>
                        </Link>
                        <Typography color="text.primary">{responseGetRegistro.titulo}</Typography>
                        <Typography color="text.primary">Eliminar</Typography>
                    </Breadcrumbs>
                }
                icon={<EventIcon />}
            />
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid size={{ xs: 12, md: 3 }}></Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <h3>¿Eliminar evento?</h3>
                        <p><img src={responseGetRegistro.imagen} height={100} /></p>
                        <p>Se eliminará del cronograma <b>{responseGetRegistro.titulo}</b> con fecha <b><Moment date={responseGetRegistro.fechayhora} format='DD-MM-YYYY' /></b> a las <b><Moment date={responseGetRegistro.fechayhora} format='HH:mm' /></b>.</p>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Divider />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button onClick={() => removeRegistro(urlParams.idCronograma)} type="button" variant="contained" color="error" startIcon={<DeleteIcon />} >
                            Eliminar
                        </Button>
                    </Grid>
                </Grid>
        </>
    )

}