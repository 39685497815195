import { React, useEffect, useState, Fragment } from 'react'
import { Link, redirect, useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux'
import { getStatusSidebar, getStateSidebarData, setStateSidebarData } from '../../store/slices/sidebar'
import { getUsuarioPermisos } from '../../store/slices/auth'

import { useGetSidebarDataMutation } from '../../store/api/endpoints/selfApiSlice'

import Loading from '../elementos/Loading'

import { SidebarIcono } from './SidebarIconos'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import '../../assets/css/Sidebar.css'

export default function Sidebar() {

    const navigate = useNavigate()
    const urlParams = useParams()
    const dispatch = useDispatch()

    const statusSidebar = useSelector(getStatusSidebar)
    const sidebarData = useSelector(getStateSidebarData)

    const [
        getSidebar,
        {
            data: responseGetSidebar,
            isUninitialized: isUninitializedGetSidebar,
            isLoading: isLoadingGetSidebar,
            isSuccess: isSuccessGetSidebar,
            isError: isErrorGetSidebar,
        }
    ] = useGetSidebarDataMutation()

    useEffect(() => {
        getSidebar()
    }, [])

    const [submenuEstado, setSubmenuEstado] = useState(
        {
            'turnos': false,
            'clientes': false,
            'caja': false,
            'configuracion': false,
        }
    )

    const handleClickMenu = (submenu) => {

        let temp = { ...submenuEstado }

        temp[submenu] = !submenuEstado[submenu]

        setSubmenuEstado(temp)

    }

    useEffect(() => {

        if (isSuccessGetSidebar) {
            dispatch(setStateSidebarData(responseGetSidebar))
        }

    }, [isSuccessGetSidebar, isErrorGetSidebar])

    if (isUninitializedGetSidebar || isLoadingGetSidebar) return (
        <div id="sidebar" className={(statusSidebar ? 'open' : 'closed')}>
            <ul>
                <li
                    className="sidebar-row"
                >
                    <Loading />
                </li>
            </ul>
        </div>
    )

    return (
        <div id="sidebar" className={(statusSidebar ? 'open' : 'closed')}>

            <List
                sx={{ width: '100%', maxWidth: 360, }}
            >
                {
                    sidebarData.map((item, index) => {

                        if (item.open) {
                            return (
                                <Fragment key={index}>
                                    <ListItemButton onClick={() => handleClickMenu(item.open)} className={"unElementoPadre " + item.className}>
                                        <ListItemIcon>
                                            <SidebarIcono codigo={item.icono} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.titulo} />
                                        {submenuEstado[item.open] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={submenuEstado[item.open]} timeout="auto" unmountOnExit>
                                        {
                                            item.subitems.map((subitem, subindex) => {
                                                return (
                                                    <List component="div" disablePadding key={subindex} className='unElementoHijo'>
                                                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate(subitem.path)}>
                                                            <ListItemIcon>
                                                                <SidebarIcono codigo={subitem.icono} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={subitem.titulo} />
                                                        </ListItemButton>
                                                    </List>
                                                )
                                            })
                                        }
                                    </Collapse>
                                </Fragment>
                            )
                        } else {
                            return (
                                <Fragment key={index}>
                                    <ListItemButton className={"unElementoPadre " + item.className}>
                                        <ListItemIcon>
                                            <SidebarIcono codigo={item.icono} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.titulo} />
                                    </ListItemButton>
                                </Fragment>
                            )
                        }

                    })
                }
            </List>

        </div >
    )

}
