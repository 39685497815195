import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { useCreateDescuentoMutation } from '../../../store/api/endpoints/descuentosApiSlice'

import { openFeedback } from '../../../store/slices/feedback'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CropImage from '../../elementos/CropImage'
import Loading from '../../elementos/Loading'

import Button from '@mui/material/Button'
import { Breadcrumbs, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import EventIcon from '@mui/icons-material/Event'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import CheckIcon from '@mui/icons-material/Check'
import CallIcon from '@mui/icons-material/Call'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

export default function DescuentosCrear() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [titulo, setTitulo] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')
    const [imagen, setImagen] = useState('')

    const [
        crearRegistro,
        {
            data: responseCrearRegistro,
            isUninitialized: isUninitializedCrearRegistro,
            isLoading: isLoadingCrearRegistro,
            isSuccess: isSuccessCrearRegistro,
            isError: isErrorCrearRegistro,
            error: errorDataCrearRegistro,
        }
    ] = useCreateDescuentoMutation()


    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            titulo,
            descripcion,
            fechaInicio,
            fechaFin,
            email,
            telefono,
            imagen,
        }
        crearRegistro({ body })

    }

    useEffect(() => {
        if (isErrorCrearRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCrearRegistro.data.message
            }))
        }
    }, [isErrorCrearRegistro])

    useEffect(() => {
        if (isSuccessCrearRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Registro creado!"
            }))

            navigate("/descuentos")

        }
    }, [isSuccessCrearRegistro])

    if (isLoadingCrearRegistro) return (<Loading />)

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/descuentos">
                            <Typography color="text.primary">Descuentos</Typography>
                        </Link>
                        <Typography color="text.primary">Crear</Typography>
                    </Breadcrumbs>
                }
                icon={<LocalOfferIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <CropImage
                            imagen={imagen}
                            setRoute={setImagen}
                            maxSize="300000"
                            width={500}
                            height={500}
                            ruta="descuentos"
                            formatosPermitidos="image/jpeg, image/png"
                            avatar
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Input
                            label="Título"
                            icon={<EventIcon />}
                            value={titulo}
                            onChange={setTitulo}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Input
                            label="Descripción"
                            icon={<EventIcon />}
                            value={descripcion}
                            onChange={setDescripcion}
                            type="text"
                            multiline
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Fecha Inicio"
                            icon={<EventIcon />}
                            value={fechaInicio}
                            onChange={setFechaInicio}
                            type="date"
                            required
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Fecha Fin"
                            icon={<EventIcon />}
                            value={fechaFin}
                            onChange={setFechaFin}
                            type="date"
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Teléfono"
                            icon={<CallIcon />}
                            value={telefono}
                            onChange={setTelefono}
                            type="text"
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Input
                            label="Email"
                            icon={<AlternateEmailIcon />}
                            value={email}
                            onChange={setEmail}
                            type="email"
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Divider />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}